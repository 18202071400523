import { useState, useEffect } from "react";
import dancarinos from "../../assets/Dancarinos.svg";
import title from "../../assets/TituloEspetaculo.svg";
import "./start.css";

function ShowTango() {
  const [isBoxVisible, setIsBoxVisible] = useState(false);

  const handleButtonClick = () => {
    setIsBoxVisible(!isBoxVisible);
  };

  useEffect(() => {
    handleButtonClick();
  }, []);

  return (
    <section className="responsive-section" style={{ display: "flex", height: "100vh" }} >
      <div className="div-geral-responsive">        
        <img className="title-espetaculo"
          style={{ position: "absolute", maxWidth: "100%", marginLeft: "30%", marginTop: "230px" }}
          src={title}
          alt="Dançarinos"
        />

    <div className="div-geral-responsive">
      <div >
        <img className="dancarinos"
          style={{ marginTop: 130, marginBottom: 10, maxWidth: "100%" }}
          src={dancarinos}
          alt="Dançarinos"
        />
      </div>
      </div>

      <div style={{ display: "flex", flexDirection: "column", alignSelf: "center", marginTop: "10%" }}>
        <div className="container-dates">
          <div className="date-containter">
                    <p className="date-title1">18 de abril</p>
                    <p className="date-title2">Quinta-feira</p>
          </div>
          <div className="date-container-description">
                    <p>19h30: Jeannette Erazú e Leandro Capparelli</p>
                    <p id="description-gap">Teatro da Escola Parque, 308 sul</p>
          </div>
        </div>
        <div className="conteiner-button">
          <a
          href="https://wa.me/+5561984840055?text=Quero%20participar%20do%20Espetáculo%20"
          target="_blank"
          className="button-ingress-value"
          >
          Garantir meu ingresso
          </a>
        </div>
      </div>
      </div>
    </section>
  );
}

export default ShowTango;
