import "./loc.css";

function Loc() {
  return (

<section id="anchor_localization">
<p className="title-map">
        Localização do Festival
      </p>
<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15355.820860211183!2d-47.8796655!3d-15.8063153!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x935a3b8e28a8a4fd%3A0x9b8ac47faeedebe8!2zRXNwYcOnbyBQcm92aWTDqm5jaWE!5e0!3m2!1spt-BR!2sbr!4v1698779048132!5m2!1spt-BR!2sbr" className="map" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
</section>

  );
}

export default Loc;



