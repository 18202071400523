import artistAnna from "../../assets/Artista_Anna.svg";
import circulo from "../../assets/image.png";
import artistsClarisaJonathan from "../../assets/Artista_ClarisaJonathan.svg";
import artistsJeanetteLeandro from "../../assets/Artista_JeanetteLeandro.svg";
import artistsMadalenaDante from "../../assets/Artista_MadalenaDante.svg";
import artistAndre from "../../assets/Artista_Andre.svg";
import artistMamana from "../../assets/Artista_Mamana.svg";
import orquestra from "../../assets/Orquestra.svg";
import "./artists.css"

function Artists() {
  return (
    <section id="anchor_artists" className="Artists">
            <a
        href="https://wa.me/+5561984840055?text=Quero%20participar%20do%20Bras%C3%ADlia%20Tango%20Festival%20" 
        target="_blank"
        className="button-ingress-top"
      >
        FALE COM A ORGANIZAÇÃO
      </a>
      <h3 className="title-atists">Artistas</h3>

      <div className="block1">
        <div className="subBlock1">
          <img className="image-artist" src={artistAnna} />
        </div>
        <div className="artist-description-anna">
          <p className="subtitle">Anna Elisa</p>
          <p className="letras">Anna Elisa ganhou sua grande reputação a nível nacional sendo a pioneira no ensino do Tango em Brasília. Com mais de duas décadas de experiência com o Tango Argentino é amplamente reconhecida a nível nacional e internacionalmente por seus feitos dedicados ao ritmo portenho.</p>
          <p className="letras">Formou centenas de alunos e profissionalizou dançarinos com seu método próprio, Descomplitango, que consiste em facilitar o ensino-aprendizagem do Tango Argentino tornando-o acessível a todos.</p>
          <p className="letras">Anna Elisa participou de shows junto a grandes nomes do Tango reconhecidos internacionalmente como Claudio Villagra, Roberto Herrera, Junior Cervilla, Aurora Lubiz entre outros.</p>
          <p className="letras">Consagrou-se campeã nacional do I Campeonato de Tango do Brasil e dançou como principal representante do país na semifinal do Campeonato Mundial de Tango em Buenos Aires no ano de 2005.</p>
          <p className="letras">Devido ao seu excelente profissionalismo e destaque, Anna Elisa foi diversas vezes convocada pelas embaixadas da Argentina e do Brasil para representar o Tango na Feira Internacional do Turismo, em Buenos Aires, e da Arabian Travel Market, em Dubai.</p>
          <p className="letras">Por dois anos Anna fez parte dos grupo de maestros do Cruzeiro Internacional Tango & Milonga da empresa Costa Cruzeiros que percorreu diversos países como Argentina e Uruguai.</p>
          <p className="letras">Anna conta com uma forte formação em Ballet Clássico havendo dedicado 10 anos no Teatro Municipal do Rio de Janeiro e em renomadas escolas de Brasília como a Escola de Dança Norma Lilia e Lúcia Toller.</p>
          <p className="letras">Anna Elisa fez diversas viagens a Buenos Aires onde dedicou vários anos aprimorando sua técnica e conhecimento sobre o Tango Argentino com artistas e mestres altamente reconhecidos como Graciela Gonzalez, Osvaldo Zotto e Lorena Ermocida,</p>
          <p className="letras">Guillermina Quiroga, Yuyu Herrera, Claude Murga, Julio Balmaceda, Corina de La Rosa, Aurora Lubiz, Moira Castellano, Claudia Codega, Esteban Moreno, Facundo e Kelly, Claudio Villagra e Helena Fernandez, Sebastian Arce, Mariana Montes entre outros.</p>
          <p className="letras">Além das habilidades artísticas, Anna Elisa conta com a formação de Jornalismo sendo graduada pela instituição Universidade Católica de Brasília e pós Graduada pela Universidade Gama Filho, tal o que lhe confere uma excelente e única capacidade de comunicação com os seus pares e alunos.</p>
        </div>

      </div>

      <section className="container-video" id="video-ajust">
        <iframe src="https://www.youtube.com/embed/s-uQyfAJVyY?si=4OC6V9Wv3tAv5vjk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        <iframe src="https://www.youtube.com/embed/_B3I74r3dso?si=7rUK5m_IcFo8LNF5"></iframe>
      </section>

            <div className="block2">
        <div className="subBlock1">
          <img className="image-artist" src={artistAndre} />
        </div>
        <div className="artist-description-anna">
          <p className="subtitle">André Carvalho</p>
          <p className="letras">Dedicado dançarino de Tango com 28 anos de experiência no campo do Tango Social. Diretor  e professor na renomada tangueria do Rio de Janeiro, a "Casa do Tango", em parceria com Alice Vasques. Combinando sua paixão pela dança com uma sólida formação acadêmica, André também possui conhecimento nas áreas de filosofia, psicologia e psicanálise.</p>
          <p className="letras">- Responsável por ministrar aulas de Tango em suas várias vertentes, incluindo o Tango Milongueiro, Salão e Tango Nuevo.</p>
          <p className="letras">- Desenvolvimento de programas de ensino personalizados para estudantes de todos os níveis.</p>
          <p className="letras">- Organização e coordenação de eventos e milongas para promover a cultura do Tango na comunidade local.</p>

        </div>

      </div>

      <section className="container-video" id="video-ajust">
        <iframe height="315" src="https://www.youtube.com/embed/5LoH9YVNsno?si=0HyNyPABQtKhB9in" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        <iframe height="315" src="https://www.youtube.com/embed/QrkEP1QGC10?si=vTiAla7YB937j5sC" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </section>

      <div className="block2">
      <div className="subBlock1">
        <img className="image-artist2" src={artistsClarisaJonathan} />
        </div>
        <div className="artist-description-anna">
          <p className="subtitle">Clarisa Aragón e Jonathan Saavedra</p>
          <p className="letras">Ela, iniciou como bailarina clássica aos 9 anos de idade, e em 2007 se juntou ao Estúdio de jazz Eugenia Calamita. Desde 2009 foi introduzida ao tango, quando teve suas primeiras aulas com seus pais Hugo Aragón e Adriana Diani.</p>
          <p className="letras" >Ele, iniciou seus estudos de folclore aos 5 anos de idade, participou de vários grupos de dança, chegando a dirigir apresentações e aberturas de festivais importantes dentro da província. No final de 2010 fez suas primeiras aulas de tango com os professores Valeria e Julio Saavedra.</p>
          <p className="letras">Em 2012, iniciaram seu trabalho profissional juntos com um estilo muito peculiar de dançar e ensinar. E em agosto de 2015 se consagraram Campeões Mundiais de Tango Salão. Hoje, viajam pelo mundo deixando contribuindo para a difusão do Tango nos mais famosos festivais e eventos do mundo.</p>
        </div>
      </div>

      <section className="container-video">
        <iframe src="https://www.youtube.com/embed/9hiPon7jBrg?si=Y6zvXfEIKVJhNuE3" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        <iframe src="https://www.youtube.com/embed/2PhoYckR8eM?si=7B4boESsDFGc8HuP" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </section>
      

      <div className="block2">
      <div className="subBlock1">
        <img className="image-artist" src={artistsJeanetteLeandro} />
        </div>
        <description className="artist-description-anna">
          <p className="subtitle">Jeanette Erazú e Leandro Capparelli</p>
          <p className="letras">Ela é a campeã metropolitana de 2022. Criadora de uma técnica que incorpora inteligência emocional à dança.</p>
          <p className="letras">Ele é vice-campeão mundial/escolhido pelo povo como o melhor casal do Mundial de 2022. Também campeão dos torneios juvenis de Cosquin, Baradero e Buenos Aires Atualmente professores da escola mundial de tango.</p>
          <p className="letras">Criadores de "Titanes de Escenário” e pista com Jeannette, eles viajaram pelo mundo em várias das mais importantes companhias de tango e ensinaram em diferentes estúdios nos Estados Unidos, Europa e Ásia.</p>
        </description>
      </div>

      <section className="container-video">
        <iframe src="https://www.youtube.com/embed/dxIGae6K53E?si=DNkzvsrdC9bjmHGK" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        <iframe src="https://www.youtube.com/embed/d46PytOqj90?si=dDuT8234KsRhRd05" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </section>

      <div className="block2">
      <div className="subBlock1">
        <img className="image-artist" src={artistsMadalenaDante} />
        </div>
        <description className="artist-description-anna">
          <p className="subtitle">Magdalena Valdez e Dante Sanchez</p>
          <p className="letras">Dante Sánchez é uma das grandes referências do tango atual. Ele é um dos dançarinos mais reconhecidos e também um divulgador da dança e cultura do Rio de la Plata.
            Sua consagração como Campeão Mundial de Tango em 2007, aos 19 anos, o levou a se posicionar no topo do Tango Argentino e a partir daí começou a fazer extensas turnês mundiais, participando dos mais importantes festivais e eventos de tango, formando parte dos mais renomados casts e casas de shows de tango.
            Sua qualidade pedagógica faz dele um dos professores mais procurados por argentinos e estrangeiros que viajam especialmente a Buenos Aires para aprender com seus conhecimentos.</p>
          <p className="letras">Magdalena Valdez é dançarina e Professora de Tango Argentino.
            Fez parte, como primeira bailarina dos mais importantes elencos e companhias, de Tango, como Tango x 2 (acompanhando o grande Miguel Angel Zotto) Esquina Carlos Gardel, e Tango Porteño por mais de 10 anos.
            Sua experiência e carreira a levaram a participar dos festivais mais importantes da Europa, Ásia e Argentina.
            Sua atuação como professora e dançarina nos diferentes estilos, Salon Tango e Stage Tango, a levou a participar repetidamente como júri do Campeonato Mundial de Tango em Buenos Aires, e em vários sub-eventos na Europa e Ásia.
          </p>
          <p className="letras">Atualmente produz e dirige seu próprio festival "Argentina Tango Salón" considerado um dos melhores festívais de tango de Bueno Aires nos ultimos 9 anos. </p>
        </description>
      </div>

      <section className="container-video">
        <iframe src="https://www.youtube.com/embed/Oa2-XRveMMA?si=CybU140OGE6EhJ7z" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        <iframe  src="https://www.youtube.com/embed/6MDGj0-py4o?si=hj5ITCUtbL6mhnxc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </section>

      <div className="block2">
      <div className="subBlock1">
        <img className="image-artist" src={artistMamana} />
        </div>
        <description className="artist-description-anna">
          <p className="subtitle">Yasmina Mamana | DJ oficial do Festival</p>
          <p className="letras">É bailarina, professora, dj e organizadora de eventos de Tango.</p>
          <p className="letras">Nascida em Formosa - Argentina, realizou seus primeiros passos de dança em sua cidade, 
          até se mudar a Buenos Aires, onde viveu 13 anos.</p>
          <p className="letras">Se introduzir ao Tango ajudou a se conectar com a cidade e as pessoas.
Em 13 anos de Tango em Buenos Aires, formou-se com grandes maestros e muitos ciclos com grandes dançarinos</p>
          <p className="letras">Sua vida competitiva começou em 2012, desde então chegou na final do Mundial de Tango Pista três vezes, compartilhando o palco do tradicional Luna Park em Buenos Aires com os melhores bailarinos de Tango do Mundo.</p>
          <p className="letras">Em 2016, com seu estilo autêntico de milonguear, consagrou-se Campeã da categoria Milonga, do campeonato mais importante depois do Mundial, Campeonato de Dança da Cidade de Buenos Aires.</p>
          <p className="letras">Em 2019, foi 3ª colocada em “Milongueros Del Mundo - Tango”. Atualmente continua desenvolvendo sua profissão em São Paulo, sua nova residência. Onde junto com seu parceiro Müller Dantas dão aulas regulares de Tango Pista na Escola da Nossa Casa Arte e Dança desde 2020. Como assim também levando seus conhecimentos ao interior do Brasil.</p>
          <p className="letras">Em 2023 junto com o seu parceiro Müller Dantas foram 2 colocados em Tango e Vals, e Campeões de Milonga na Preliminar de Tango Brasil.</p>
          <p className="letras">Nas suas aulas grupais e particulares, ressalta a importância da caminhada, o abraço, conexão com companheiro e a música como ingredientes essenciais para desfrutar a dança.</p>
          <p className="letras">Seu trabalho como Dj continua em crescimento. Sendo a Dj residente da Milonga "La Rebelde" desde 2022, e desempenhando seu trabalho como musicalizadora nas diferentes Milongas do circuito Tanguero de São Paulo como assim também em Festivais: "Festivalito SP", Metropolitano Brasil no 2022, Congresso de Tango Curitiba no 2023.</p>
          <p className="letras">Seu último projeto em 2022 é em conjunto com colegas conformando a Sociedade "La Máquina Tanguera" que lança pela primeira vez no Brasil o "Festival e Campeonato Metropolitano"</p>
        </description>
      </div>

      <h3 className="title-atists">Orquestra Tango Revirado</h3>

      <img className="orquestra" src={orquestra}/>
      <a
        href="https://wa.me/+5561984840055?text=Quero%20participar%20do%20Bras%C3%ADlia%20Tango%20Festival%20"
        target="_blank"
        className="button-ingress"
      >
        FALE COM A ORGANIZAÇÃO
      </a>

    </section>


  );
}

export default Artists;
