
import "./dates.css"



function Dates() {
    return (
        <section id="anchor_program" className="section-dates">
            <h3 className="title-date">Programação</h3>

            <div className="container-dates">
                <div className="date-containter">
                    <p className="date-title1">18 de abril</p>
                    <p className="date-title2">Quinta-feira</p>
                </div>
                <div className="date-container-description">
                    <p>19h30:Espetáculo Teatro </p>
                    <p id="description-gap">Local: Teatro Escola Parque, 308 sul </p>

                    <p>21h - 2h: Milonga de abertura </p>
                    <p>Local: Espaço Providência, 601 Sul, L2 sul</p>
                </div>
            </div>

            <div className="container-dates">
                <div className="date-containter">
                    <p className="date-title1">19 de abril</p>
                    <p className="date-title2">Sexta-feira</p>
                </div>
                <div className="date-container-description">
                    <p>13h30 - 14h30: Clarisa Aragon e Jonathan Saavedra </p>
                    <p id="description-gap">Tema: Tudo sobre o Pivot: Como e quando aplicamos? Sequências!</p>

                    <p>14h30 - 15h30: Magdalena Valdez e Dante Sanchez</p>
                    <p id="description-gap">Tema: Dança desde a raiz - Conexão com o chão e seus efeitos e repercussões no tronco. Técnica geral.</p>

                    <p id="description-gap">Intervalo</p>

                    <p>16h - 17h: Jeannette Erazú e Leandro Capparelli </p>
                    <p id="description-gap">Tema: Movimentos circulares e sutilezas em pequenos espaços.</p>

                    <p>17h - 18h: Jeannette Erazú e Leandro Capparelli </p>
                    <p id="description-gap">Tema: Figura complexa em abraço fechado. Alterações e enfeites.
                    Local de todas as aulas: Espaço Providência, 601 Sul, L2 sul</p>

                    <p>21h: Milonga </p>
                    <p id="description-gap">Local: Espaço Providência - L2 601 sul</p>

                    <p>00h: Show </p>
                    <p id="description-gap">Local: Espaço Providência - L2 601 sul</p>
                </div>
            </div>

            <div className="container-dates">
                <div className="date-containter">
                    <p className="date-title1">20 de abril</p>
                    <p className="date-title2">Sábado</p>
                </div>
                <div className="date-container-description">
                    <p>13h30 - 14h30: Magdalena Valdez e Dante Sanchez</p>
                    <p id="description-gap">Tema: A Pausa VS a ausência de movimento - Como interpretar a lentidão com conteúdo real de movimento.</p>

                    <p>14h30 - 15h30: Magdalena Valdez e Dante Sanchez</p>
                    <p id="description-gap">Tema: Figuras com efeito - Como usar intenções e dinâmicas para aprimorar suas figuras.</p>

                    <p id="description-gap">Intervalo</p>

                    <p>16h - 17h: Clarisa Aragon e Jonathan Saavedra</p>
                    <p id="description-gap">Tema: Milonga - Ritmo e cadência, diferentes exercícios rítmicos.</p>

                    
                    <p>17h - 18h: Jeannette Erazú e Leandro</p>
                    <p id="description-gap">Tema: Mudanças de dinâmicas geradas por diferentes acentuações.
                    Local de todas as aulas: Espaço Providência, 601 Sul, L2 sul</p>

                    <p>21h: Milonga</p>
                    <p id="description-gap">Local: Espaço Providência, 601 Sul, L2 sul</p>

                    <p>00h: Show</p>
                    <p id="description-gap">Local: Espaço Providência, 601 Sul, L2 sul</p>

                </div>
            </div>


            <div className="container-dates">
                <div className="date-containter">
                    <p className="date-title1">21 de abril</p>
                    <p className="date-title2">Domingo</p>
                </div>
                <div className="date-container-description">
                    <p>13h30 - 14h30: Jeannette Erazú e Leandro Capparelli</p>
                    <p id="description-gap">Tema: Elásticos e força centrífuga para gerar velocidade.</p>

                    <p>14h30 - 15h30: Magdalena Valdez e Dante Sanchez</p>
                    <p id="description-gap">Tema: Do salão ao palco - Ferramentas que coexistem e se aprimoram em diferentes estilos. Como e onde aplicá-los.</p>

                    <p id="description-gap">Intervalo</p>

                    <p>16h - 17h: Clarisa Aragon e Jonathan Saavedra</p>
                    <p id="description-gap">Tema: Tango Vals - Brincando com circularidade e correntes.</p>

                    <p>17h - 18h: Clarisa Aragon e Jonathan Saavedra</p>
                    <p id="description-gap">Tema: Sacadas - Técnica e exercícios para diferentes alternativas de Sacadas.
                    Local de todas as aulas: Espaço Providência, 601 Sul, L2 sul</p>

                    <p>21h: Milonga</p>
                    <p id="description-gap">Local: Espaço Providência, 601 Sul, L2 sul</p>

                    <p>23h:Show</p>
                    <p>Espaço Providência, 601 Sul, L2 sul</p>
                </div>
            </div>


            <p className="program-description">* programação sujeita a alteração sem aviso prévio</p>
        </section>
    );
  }
  
  export default Dates;
  