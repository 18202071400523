import { useState, useEffect } from "react";
import logoPranchetaMain from "../../assets/BTF_logoPranchetaMain.svg";
import "./start.css";

function Start() {
  const [isBoxVisible, setIsBoxVisible] = useState(false);

  const handleButtonClick = () => {
    setIsBoxVisible(!isBoxVisible);
  };

  useEffect(() => {
    handleButtonClick();
  }, []);

  return (
    <section style={{ marginBottom: 100 }} id="anchor_event" className="Start">
      <div></div>
      <div className={`box ${isBoxVisible ? "slide-in" : "slide-out"}`}>
        <img
          style={{ marginTop: 130, marginBottom: 10, maxWidth: "100%" }}
          src={logoPranchetaMain}
          alt="Logo Prancheta Main"
        />
        <p className="date-rosa">18 a 21 de abril de 2024</p>
      </div>
      <div className="names-artists">
        <div>
          <p className="contNames">ANNA ELISA IUNG</p>
        </div>
        <div>
          <p className="contNames">ANDRÉ CARVALHO</p>
        </div>
        <div>
          <p className="contNames">CLARISA ARAGÓN &</p>
          <p>JONATHAN SAAVEDRA</p>
        </div>
        <div>
          <p className="contNames">JEANETTE ERAZU &</p>
          <p>LEANDRO CAPPARELLI</p>
        </div>
        <div>
          <p className="contNames">MAGDALENA VALDEZ &</p>
          <p style={{width: 200, textAlign:"center"}}>DANTE SANCHEZ</p>
        </div>
      </div>
    </section>
  );
}

export default Start;
