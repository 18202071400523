import React, { useState } from 'react';
import './primeCarousel.css';
import { Galleria } from 'primereact/galleria';
import camisa1 from '../../assets/camisa1.svg'
import camisa2 from '../../assets/camisa2.svg'
import camisa3 from '../../assets/camisa3.svg'
import camisa4 from '../../assets/camisa4.svg'
import camisa5 from '../../assets/camisa5.svg'

const produtos = [
  { id: 1, src: camisa1, thumbnail: camisa1, nome: 'Camiseta Tradicional unissex', cor: 'Cor: Preta', estampa: 'Estampa: Mosaico', caracteristica: 'Características: Gola redonda', material: 'Material: Malha fria', tamanhos: 'Tamanhos: P M G' , detalhes: 'Essa estampa foi inspirada nos mosaicos de azulejo do artista Athos Bulcão que adornam vários monumentos da Capital do Brasil. A criadora, Carol Esteca, brincou com fragmentos da imagem dos Dois Candangos, representados na logo, para criar essa linda referência do Festival e da cidade sede do nosso evento, que acontecerá de 18 a 21 de abril de 2024.', aviso: 'As imagens são meramente ilustrativas exclusivamente para observação do layout da impressão das estampas da camisetas.' },
  { id: 2, src: camisa2, thumbnail: camisa2, nome: 'Camiseta Longuete unissex', cor: 'Cor: Preta', estampa: 'Estampa: Mosaico', caracteristica: 'Características: Barra com corte arredondado, modelagem tradicional, e gola arredondada', material: 'Material: Viscolycra', tamanhos: 'Tamanhos: P M G' , detalhes: 'Essa estampa foi inspirada nos mosaicos de azulejo do artista Athos Bulcão que adornam vários monumentos da Capital do Brasil. A criadora, Carol Esteca, brincou com fragmentos da imagem dos Dois Candangos, representados na logo, para criar essa linda referência do Festival e da cidade sede do nosso evento, que acontecerá de 18 a 21 de abril de 2024.', aviso: 'As imagens são meramente ilustrativas exclusivamente para observação do layout da impressão das estampas da camisetas.' },
  { id: 3, src: camisa3, thumbnail: camisa3, nome: 'Camiseta Longuete feminina', cor: 'Cor: Preta', estampa: 'Estampa: Mosaico', caracteristica: 'Características: Barra com corte quadrado, fendas laterais, modelagem babylook e gola arredondada ', material: 'Material: Viscolycra', tamanhos: 'Tamanhos: P M G' , detalhes: 'Essa estampa foi inspirada nos mosaicos de azulejo do artista Athos Bulcão que adornam vários monumentos da Capital do Brasil. A criadora, Carol Esteca, brincou com fragmentos da imagem dos Dois Candangos, representados na logo, para criar essa linda referência do Festival e da cidade sede do nosso evento, que acontecerá de 18 a 21 de abril de 2024.', aviso: 'As imagens são meramente ilustrativas exclusivamente para observação do layout da impressão das estampas da camisetas.' },
  { id: 4, src: camisa4, thumbnail: camisa4, nome: 'Camiseta Babylook', cor: 'Cor: Preta', estampa: 'Estampa: Mosaico', caracteristica: 'Características: Gola redonda', material: 'Material: Malha fria', tamanhos: 'Tamanhos: P M G' , detalhes: 'Essa estampa foi inspirada nos mosaicos de azulejo do artista Athos Bulcão que adornam vários monumentos da Capital do Brasil. A criadora, Carol Esteca, brincou com fragmentos da imagem dos Dois Candangos, representados na logo, para criar essa linda referência do Festival e da cidade sede do nosso evento, que acontecerá de 18 a 21 de abril de 2024.', aviso: 'As imagens são meramente ilustrativas exclusivamente para observação do layout da impressão das estampas da camisetas.' },
  { id: 5, src: camisa5, thumbnail: camisa5, nome: 'Camiseta Longuete unissex', cor: 'Cor: Preta', estampa: 'Estampa: Orquestras', caracteristica: 'Características: Barra com corte arredondado, modelagem tradicional, e gola arredondada', material: 'Material: Viscolycra', tamanhos: 'Tamanhos: P M G' , detalhes: 'Essa estampa foi inspirada nos mosaicos de azulejo do artista Athos Bulcão que adornam vários monumentos da Capital do Brasil. A criadora, Carol Esteca, brincou com fragmentos da imagem dos Dois Candangos, representados na logo, para criar essa linda referência do Festival e da cidade sede do nosso evento, que acontecerá de 18 a 21 de abril de 2024.', aviso: 'As imagens são meramente ilustrativas exclusivamente para observação do layout da impressão das estampas da camisetas.'},
  // ... (adicionar outros produtos)
];

const opcoesResponsivas = [
  { breakpoint: '1024px', numVisible: 1, numScroll: 1 },
];

const MeuCarousel = () => {
  const [detalhesVisiveis, setDetalhesVisiveis] = useState(false);

  const toggleDetalhes = () => {
    setDetalhesVisiveis((prevDetalhesVisiveis) => !prevDetalhesVisiveis);
  };

  const itemTemplate = (item) => {
    return (
      <div className={`item-produto ${detalhesVisiveis ? 'detalhes-visiveis' : ''}`} onClick={toggleDetalhes}>
        <div className="conteudo-item-produto">
          <img
            src={item.src}
            alt={item.alt}
            className={`imagem-produto ${detalhesVisiveis ? 'imagem-reduzida' : ''}`}
          />
        </div>
        {detalhesVisiveis && (
          <div className="detalhes-produto">
            <p className="nome-produto">{item.nome}</p>
            <p className="cor-produto">{item.cor}</p>
            <p className="estampa-produto">{item.estampa}</p>
            <p className="caracteristica-produto">{item.caracteristica}</p>
            <p className="material-produto">{item.material}</p>
            <p className="tamanhos-produto">{item.tamanhos}</p>
          </div>
        )}
         {detalhesVisiveis && (
          <div className="detalhes-abaixo">
            <p className="detalhes-produto">{item.detalhes}</p>
            <p className="detalhes-aviso">{item.aviso}</p>
          </div>
        )}
      </div>
    );
  };

  const thumbnailTemplate = (item) => {
    return (
      <img
        src={item.thumbnail}
        alt={item.alt}
        className="imagem-thumbnail"
        onClick={() => console.log('Thumbnail clicada')} // Adapte conforme necessário
      />
    );
  };

  return (
    <section className="container-carrossel">
      <a href='https://wa.me/+5561984840055?text=Quero%20comprar%20uma%20das%20camisas%20do%20Bras%C3%ADlia%20Tango%20Festival%20'
        target="_blank"
       className="title-carrossel">Clique aqui para garantir as camisetas oficiais do festival</a>
<Galleria value={produtos} numVisible={3}  item={itemTemplate} thumbnail={thumbnailTemplate}  circular />
    </section>
  );
};

export default MeuCarousel;