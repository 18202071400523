import React, { useEffect, useState } from 'react';
import { Galleria } from 'primereact/galleria';
import foto1 from '../../assets/imgscarrosel/AGN01759.jpg';
import foto2 from '../../assets/imgscarrosel/AGN02479.jpg';
import foto3 from '../../assets/imgscarrosel/AGN03623.jpg';
import foto4 from '../../assets/imgscarrosel/AGN05037.jpg';
import foto5 from '../../assets/imgscarrosel/AGN05063.jpg';
import foto6 from '../../assets/imgscarrosel/AGN05111.jpg';
import './styles.css'; 


const Photos = () => {
  const [images, setImages] = useState([
    {
      itemImageSrc: foto1,
      alt: 'foto1',
      thumbnailImageSrc: foto1
    },
    {
      itemImageSrc: foto2,
      alt: 'foto2',
      thumbnailImageSrc: foto2
    },
    {
      itemImageSrc: foto3,
      alt: 'foto3',
      thumbnailImageSrc: foto3
    },
    {
      itemImageSrc: foto4,
      alt: 'foto4',
      thumbnailImageSrc: foto4
    },
    {
      itemImageSrc: foto5,
      alt: 'foto5',
      thumbnailImageSrc: foto5
    },
    {
      itemImageSrc: foto6,
      alt: 'foto6',
      thumbnailImageSrc: foto6
    },

  ]);
  const [pageIndex, setPageIndex] = useState(0);

  const responsiveOptions = [
    {
      breakpoint: '991px',
      numVisible: 4
    },
    {
      breakpoint: '767px',
      numVisible: 3
    },
    {
      breakpoint: '575px',
      numVisible: 1
    }
  ];

  const itemTemplate = (item) => {
    return <img src={item.itemImageSrc} alt={item.alt} style={{ width: '100%' }} />
  }

  const thumbnailTemplate = (item) => {
    return <img src={item.thumbnailImageSrc} alt={item.alt} style={{ width: '100%' }} />
  }

  return (
    <div style={{
      width: '100%',
      backgroundColor: 'black',
      zIndex: 0,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: 30
    }}>
      <text style={{ color: 'white', marginTop: 50, marginBottom: 50, fontSize: 40 }}>Fotos</text>
      <Galleria value={images} responsiveOptions={responsiveOptions} numVisible={5} style={{ maxWidth: '840px' }}
        item={itemTemplate} thumbnail={thumbnailTemplate} />
      <text className='textLink' style={{ color: 'white', marginTop: 50, marginBottom: 50, fontSize: 30 }}>Clique nas galerias abaixo, com os nomes dos dias da semana, para acessar os registros fotográficos do Festival.</text>
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: "center", alignItems: "center" }}>
        <a href="https://drive.google.com/drive/folders/14tb352yGJ4E-vdQ05xqOfS94DGVPuGEr?usp=sharing" target="_blank" style={{ color: '#FF0093', margin: 20, fontSize: 30, textDecoration: 'none' }}>
          Quinta-Feira
        </a>
        <a href="https://drive.google.com/drive/folders/1X_lFgdudWbU4yKbD01vB1OWYoynmTFsa?usp=sharing" target="_blank" style={{ color: '#FF0093', margin: 20, fontSize: 30, textDecoration: 'none' }}>
          Sexta-Feira
        </a>
        <a href="https://drive.google.com/drive/folders/1AWzjajVrK-4jfq6qTw0026SCOXrJ_LMM?usp=sharing" target="_blank" style={{ color: '#FF0093', margin: 20, fontSize: 30, textDecoration: 'none' }}>
          Sábado
        </a>
        <a href="https://drive.google.com/drive/folders/1WS_ZcPgOilcWe3mWFZPf7mPzRHnDp1tq?usp=sharing" target="_blank" style={{ color: '#FF0093', margin: 20, fontSize: 30, textDecoration: 'none', marginBottom: 200 }}>
          Domingo
        </a>
      </div>
    </div>
  );
}

export default Photos;
