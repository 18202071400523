import footerPessoas from "../../assets/Footer_Pessoas.svg";
import logoAnna from "../../assets/logoAnna.svg";
import "./footer.css"



function Footer() {
    return (
        <div>
            <section className="logos-extras">
                <p>Produção:</p>
                <img src={logoAnna} />
            </section>
            <img className="footer" src={footerPessoas}/>
        </div>
    );
  }
  
  export default Footer;
  