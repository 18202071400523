import Polygon from "../../assets/Polygon.svg"
import "./value.css";

function Value() {
  return (
    <section className="Value">
      <section className="all-ingress-values">
        <div className="container-ingress">
          <p className="lote-ingress">FULL PASS 2º lote</p>
          <img className="polygon" src={Polygon}/>
          <ul className="list-ingress">
            <li>3 dias de aula</li>
            <li>4 Milongas</li>
            <li>1 noite de espetáculo com Orquestra</li>
          <p className="price-ingress">R$ 950</p>
          <p className="finaly-lote"></p>
          <p className="finaly-lote2"></p>
          </ul>
        </div>
        <div className="container-ingress">
          <p className="lote-ingress">MILONGA PASS </p>
          <img className="polygon" src={Polygon}/>
          <ul className="list-ingress">
            <li>4 noites de Milonga com show</li>
          <p className="price-ingress">R$ 385</p>
          </ul>
        </div>
        <div className="container-ingress">
          <p className="lote-ingress">WEEKEND PASS</p>
          <img className="polygon" src={Polygon}/>
          <ul className="list-ingress">
            <li>2 dias de aula</li>
            <li>2 Milongas</li>
          <p className="sub-dresc-ingress">Os 2 dias são de sua escolha</p>
          <p className="price-ingress">R$ 545</p>
          </ul>
        </div>
        <div className="container-ingress">
          <p className="lote-ingress">PACOTE VIP SPONSOR</p>
          <img className="polygon" src={Polygon}/>
          <ul className="list-ingress-vip">
            <li>Full Pass</li>
            <li>Acento especial no espetáculo </li>
            <li>Coquetel com os artistas</li>
            <li>Mesa reservada nas Milongas</li>
            <li>1 garrafa de espumante </li>
            <li>1 souvenir da Griffe do Festival </li>
            <li>20 % de desconto em sapatos ou roupas do evento</li>
          <p className="price-ingress-vip">R$ 1500</p>
          </ul>
        </div>
      </section>
      <div className="conteiner-button">
        <a
        href="https://wa.me/+5561984840055?text=Quero%20participar%20do%20Bras%C3%ADlia%20Tango%20Festival%20"
        target="_blank"
        className="button-ingress-value"
        >
        FALE COM A ORGANIZAÇÃO
        </a>
      </div>
    </section>
  );
}

export default Value;
